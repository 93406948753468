@charset "utf-8";
/* CSS Document */

@import "import.scss";

/* COMMON PARTS-------------------------------------------*/
.pagetype-notfound {
  .ft-nav {
    display: none;
  }
}

@media screen and (min-width: 769px) {
  .pagetype-notfound {
  }
}

.main-area {
  text-align: center;
  .ttl {
    font-size: 30px;
    color: #898888;
    @include fontLatin;
    font-weight: bold;
    margin-bottom: 20px;
    letter-spacing: 0.1em;
  }
  .img-box {
    margin-bottom: 19px;
    img {
      width: 99px;
    }
  }
  .txt-01 {
    font-size: 15px;
    margin-bottom: 15px;
    font-weight: bold;
  }
  .txt-02 {
    margin-bottom: 15px;
  }
}
@media screen and (min-width: 769px) {
  .main-area {
    .ttl {
      font-size: 50px;
      margin-bottom: 45px;
    }
    .img-box {
      margin-bottom: 31px;
      img {
        width: 198px;
      }
    }
    .txt-01 {
      font-size: 26px;
      margin-bottom: 25px;
    }
    .txt-02 {
      margin-bottom: 40px;
    }
  }
}
